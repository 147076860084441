import React, { Component } from 'react';
import { Countdown } from './components/Countdown';

const time = window.ASC_COUNTDOWN_TIME;
const link = window.ASC_COUNTDOWN_LINK;
const day = window.ASC_COUNTDOWN_DAY;

class App extends Component {
  render() {
    return (
      <Countdown time={time} day={day} link={link} />
    );
  }
}

export default App;
