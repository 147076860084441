import React, { useState, useEffect, useRef } from 'react';

export const Countdown = ({ time, day = 0, link }) => {
  const [days, setDays] = useState(6);
  const [hours, setHours] = useState(23);
  const [minutes, setMinutes] = useState(59);
  const [seconds, setSeconds] = useState(59);
  const [isLive, setIsLive] = useState(true);
  const [isClose, setIsClose] = useState(false);

  useEffect(() => {
    const today = new Date();
    const end = new Date(time);

    // Get end date set to today while keeping time
    end.setFullYear(today.getFullYear());
    end.setMonth(today.getMonth());
    end.setDate(today.getDate());

    let tempDays = 0;
    let tempHours = 0;
    let tempMin = 0;

    // Increment end day until it matches set day
    while (end.getDay() !== day) {
      tempDays++;
      end.setDate(end.getDate() + 1);
    }

    // Add a week if service time passed
    if (end.getDay() === today.getDay() && end.getTime() < today.getTime()) {
      end.setDate(end.getDate() + 7);
    }

    if (end.getHours() < today.getHours()) {
      tempDays = tempDays === 0 ? 6 : tempDays - 1;
      tempHours = end.getHours() + 24 - today.getHours();
    } else {
      tempHours = end.getHours() - today.getHours();
    }

    if (end.getMinutes() < today.getMinutes()) {
      tempHours = tempHours === 0 ? 23 : tempHours - 1;
      tempMin = end.getMinutes() + 60 - today.getMinutes();
    } else {
      tempMin = end.getMinutes() - today.getMinutes();
    }

    if (end.getSeconds() < today.getSeconds()) {
      tempMin = tempMin === 0 ? 59 : tempMin - 1;
      setSeconds(end.getSeconds() + 60 - today.getSeconds());
    } else {
      setSeconds(end.getSeconds() - today.getSeconds());
    }

    setMinutes(tempMin);
    setHours(tempHours);
    setDays(tempDays);
    setIsLive(false);
  }, []);

  const tick = () => {
    let tempSeconds = seconds;
    let tempMin = minutes;
    let tempHour = hours;
    let tempDays = days;

    if (tempDays === 0 && tempHour === 0 && tempMin <= 10 && !isClose) {
      setIsClose(true);
    } else if (tempDays === 0 && tempHour === 0 && tempMin === 0 && tempSeconds === 0) {
      setIsLive(true);
    }

    tempSeconds--;
    if (tempSeconds < 0) {
      tempMin--;
      tempSeconds = 59;
    }
    setSeconds(tempSeconds);

    if (tempMin < 0) {
      tempHour--;
      tempMin = 59;
    }
    setMinutes(tempMin);

    if (tempHour < 0) {
      tempDays--;
      tempHour = 23;
    }
    setHours(tempHour);

    if (tempDays < 0) {
      setIsLive(true);
    }
    setDays(tempDays);
  }

  useInterval(tick, isLive ? null : 1000);

  return (
    <div className="countdown-container">
      {!isLive &&
        <a href={link} className="countdown-link"
          target="_blank" rel="noopener noreferrer">
          <div className="countdown-days">
            <h5>Days</h5>
            <span className="days-text">{days}</span>
          </div>
          <div className="countdown-hours">
            <h5>Hours</h5>
            <span className="hours-text">{hours}</span>
          </div>
          <div className="countdown-minutes">
            <h5>Minutes</h5>
            <span className="minutes-text">{minutes}</span>
          </div>
          <div className="countdown-seconds">
            <h5>Seconds</h5>
            <span className="seconds-text">{seconds}</span>
          </div>
        </a>
      }
      {isClose &&
        <a href={link} className="countdown-button"
          target="_blank" rel="noopener noreferrer">
          Streaming Now
            </a>
      }
    </div>
  )
}

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}